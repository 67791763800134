.bg-shadow {
  background-color: rgba(0, 0, 0, 0.1);
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 12312;
}

.sidebar {
  background-color: #fff; /* or any color you prefer */
  box-shadow: 2px 2px 1px rgba(195, 195, 195, 0.2); /* Offset-X, Offset-Y, Blur-Radius, Color */
  height: 100vh;
  overflow-x: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%); /* Starts hidden to the right */
  transition: transform 0.4s ease; /* ease for a smoother slide */
  width: 450px; /* or desired width */
  z-index: 12931;
}

.sidebar.open {
  transform: translateX(0); /* Slide in to its original position */
}
