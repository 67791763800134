.navbar-brand {
  img {
    width: 50px;
  }
}

.user--image {
  border-radius: 2000px;
  height: 30px;
}

.dropdown-menu {
  z-index: 20000;
}

body.sidebar--open {
  .container-fluid {
    transition: width 0.4s ease;
    width: calc(100% - 250px);
  }
}

body {
  .container-fluid {
    transition: width 0.4s ease;
    width: calc(100% - 100px);
  }
}
