.project-group--booking {
  display: inline-block;
  height: 35px;

  margin-top: 4px;
}

.resource--name button {
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
}

.project-group--booking-timeline {
  height: 43px;
  transition: all 250ms ease-in-out;

  &:hover {
    background-color: rgba($color: #e3e3e3, $alpha: 0.35);
  }
}

.resource--name {
  background-color: #fff;
  font-size: 12px;
  padding: 0;
  padding-left: 4px;
  text-align: right;
  z-index: 100;
}
