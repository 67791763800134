.expand-resource {
  cursor: pointer;
}

.resource-absence {
  height: 25px;
}

.resource--project {
  background-color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-align: center;

  transition: background-color 0.25s ease;
  z-index: 100;

  &:focus {
    background-color: #f8f8f8;
  }

  &:hover {
    background-color: #f8f8f8;
  }

  button {
    font-size: 12px;
  }
}
