.construction-manager-row {
  .resource--name {
    background-color: #fff;
    font-size: 12px;
    font-weight: 400;
    padding: 0;
    padding-left: 4px;
    text-align: right;
    z-index: 100;
  }

  .construction-manager--project {
    background-color: #fff;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    text-align: center;

    transition: background-color 0.25s ease;
    z-index: 100;

    &:focus {
      background-color: #f8f8f8;
    }

    &:hover {
      background-color: #f8f8f8;
    }

    button {
      font-size: 12px;
    }
  }

  .timeline {
    background-color: #8bb2fb;
    height: 25px;
  }

  .construction-manager-combined {
    height: 41px;

    .timeline {
      background-color: #0f62fe;
      height: 25px;
    }
  }
}
