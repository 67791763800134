.sticky-bottom {
  bottom: 0;
  position: sticky;
  z-index: 1020;
}

.sticky-left {
  left: 0;
  position: sticky;
  z-index: 1020;
}

.sticky-right {
  position: sticky;
  right: 0;
  z-index: 1020;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}
