/* stylelint-disable */
.react-datepicker__month-container {
  position: unset;
  z-index: 200;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 2300;
}

.react-datepicker__triangle {
  left: -19px;
}
/* stylelint-enable */
