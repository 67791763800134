.booking {
  border-radius: 0;
  color: rgba(42, 48, 74, 0.5);
  height: 50px;

  &.active {
    color: #000;
  }

  &:hover {
    outline: 2px solid #e5f4f7;
  }

  &:focus {
    outline: unset;
  }
}
