.project-details {
  display: grid;
  gap: 15px; /* Adjust the gap between items as needed */
  grid-template-columns: repeat(2, 1fr); /* Two columns per row */

  h6,
  p {
    margin-bottom: 0;
  }
}

.gap {
  gap: 10px;
}

.project--status {
  border-radius: 400px;
  height: 15px;
  width: 15px;

  &.completed {
    background-color: rgb(255, 0, 0);
  }

  &.planning {
    background-color: #cee6fe !important;
  }

  &.started {
    background-color: rgb(0, 128, 0);
  }

  &.starting {
    background-color: rgb(255, 153, 0);
  }
}

.actions {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr); /* Two columns per row */

  a {
    text-align: center;
  }
}
