.project-resource--booking {
  display: inline-block;
  height: 25px;
}

.resource--name {
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  padding-left: 4px;
  text-align: right;
  z-index: 100;
}
