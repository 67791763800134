#project_search {
  border: 0;
  border-radius: 0;
  height: 50px;
  width: 80%;

  &:focus {
    border: 0;
    box-shadow: unset;
    outline: 0;
  }
}

#search-button {
  width: 20%;

  button {
    height: 100%;
    width: 100%;
  }
}

.clear--search {
  background: unset;
  border: unset;
  right: calc(22%);
  top: 12px;
  z-index: 20000;
}
