.users-search {
  height: 60px;
  width: 100%;
}

.users-search-reset {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 17px;
}

.users-cards {
  display: grid;
  gap: 10px; /* Adjust the gap between items as needed */
  grid-template-columns: repeat(2, 1fr); /* Two columns per row */
}
