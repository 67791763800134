.roles {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr); /* Two columns per row */
}

.role {
  cursor: pointer;
  padding: 8px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}

.password {
  box-shadow: none !important;
}

.password-error {
  font-size: 12px;
}
