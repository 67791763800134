.user-card-image {
  height: 100%;
  transition: transform 0.4s ease-in-out; /* Adjust the duration and timing function as needed */
  width: 100%;
}

.user-card-container {
  border-radius: 20000px;
  height: 100px;
  overflow: hidden;
  width: 100px;
}

.user-card-roles {
  .role {
    max-width: 110px;
  }
}

.user-card {
  box-shadow: 0 0 0 rgba(195, 195, 195, 0);
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out; /* Adjust the duration and timing function as needed */
}

.user-card:hover {
  box-shadow: 2px 2px 1px rgba(195, 195, 195, 0.2); /* Offset-X, Offset-Y, Blur-Radius, Color */
  .user-card-image {
    transform: scale(1.02);
  }
}
