.text-fade {
  animation: fadeAnimation 0.5s ease;
}

@keyframes fadeAnimation {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

.calendar--timeline {
  background-color: #fff;
  height: 50px;

  .week {
    border-right: 1px solid #dee2e6 !important;
    display: inline-block;
    position: relative;
    text-align: left;
    z-index: 1000;

    &:first-child {
      .week-number {
        border-left: 1px solid #dee2e6 !important;
      }

      .day:first-of-type {
        border-left: 1px solid #dee2e6 !important;
      }
    }
  }

  .month--placeholder {
    height: 20px;
  }

  .week-number {
    padding: 5px;
    position: relative;
  }

  .days-active {
    font-weight: bold;
  }

  .month-names {
    background-color: #f8f8f8;
    height: 1.5rem;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }

  .day {
    border-right: 1px solid #dee2e6 !important;
    border-top: 1px solid #dee2e6 !important;
    display: inline-block;
    text-align: center;
  }

  .weekend {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
