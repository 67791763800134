.side-nav {
  display: flex;
  flex-direction: column;
  height: 100vh;
  transition: width 0.4s ease; /* ease for a smoother slide */
  width: 100px;

  .d-flex {
    transition: all 0.4s ease; /* ease for a smoother slide */
    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

.side-nav-header {
  cursor: pointer;
}

.side-nav-item {
  img {
    transition: all 0.4s ease; /* ease for a smoother slide */
  }

  &:hover {
    img {
      background-color: #a2b5ab;
      transform: scale(1.02);
      transition: all 0.4s ease; /* ease for a smoother slide */
    }
  }
}

.side-nav.open {
  transition: width 0.4s ease; /* ease for a smoother slide */
  width: 250px;

  .d-flex {
    transition: all 0.4s ease; /* ease for a smoother slide */
    p {
      margin-bottom: 0;
      margin-left: 20px;
    }
  }
}
