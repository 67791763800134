.project-resource-group--name {
  font-weight: 600;

  span {
    color: #bababa;
    font-weight: 400;
  }
}

.project-resource-group {
  border-bottom: 1px solid #e6e6e6;
  position: relative;

  .overlay {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 950;

    .inner {
      align-items: center;
      background-color: rgba($color: #000, $alpha: 0.2);
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;

      .undo-delete {
        margin-top: -5px;
      }
    }
  }
}
