.overflow-y-hidden {
  overflow-x: hidden;
  overflow-y: hidden;
}

.fs-small {
  font-size: 12px;
}

.fs-huge {
  font-size: 70px;
}
