$theme-colors: (
  "primary": $byens-blue,
  "secondary": $byens-green,
  "success": $byens-green,
  "info": $byens-yellow,
  "warning": $byens-pink,
  "dark": $byens-darkblue,
  "grey": $grey,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$font-family-sans-serif: aktiv-grotesk, aktiv-grotesk-extended;

$headings-font-weight: 600;

@import "bootstrap";
