.estimate {
  background-color: #cee6fe !important;
}

.project .timeline {
  background-color: #566965;
  height: 50px;
  margin-bottom: 4px;
  margin-top: 4px;
}

.project-warnings-and-edit {
  display: flex;
  gap: 5px;
}

.project--id {
  color: rgb(149, 149, 149);
  font-size: 12px;
}

.project--actions {
  font-size: 12px;

  button {
    font-size: 12px;
  }
}

.project--name {
  background-color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  text-align: center;

  transition: background-color 0.25s ease;
  z-index: 100;

  &:focus {
    background-color: #f8f8f8;
  }

  &:hover {
    background-color: #f8f8f8;
  }
}

.project--name button {
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
}

.warning-image img {
  height: 15px;
  margin-top: -3px;
  width: 15px;
}
