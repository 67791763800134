.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.height-top-bars {
  height: 75px;
}

.timeline {
  transition: all 0.075s ease-in-out;
}

.calendar-resource {
  transition: all 0.075s ease-in-out;
}

.calendar-resource-group {
  transition: all 0.075s ease-in-out;
}

.project-resource--booking {
  transition: all 0.075s ease-in-out;
}
