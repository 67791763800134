.project--days {
  height: 25px;

  .project--day {
    display: inline-block;
    height: 100vh;
    position: relative;
    text-align: center;
    width: 26px;
  }
}

.project--booker {
  position: relative;
}

.position-unset {
  position: unset;
}
