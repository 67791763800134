.sessions form,
.registrations form {
  margin: auto;
  max-width: 44.5rem;
  width: 100%;

  .form-control {
    box-sizing: border-box;
    font-size: 1rem;
    height: auto;
    padding: 0.75rem;
    position: relative;

    &:focus {
      z-index: 2;
    }

    &::placeholder {
      color: $grey;
    }
  }

  input[type="email"] {
    margin-bottom: -1px;
  }

  input[type="password"] {
    margin-bottom: 0.75rem;
  }
}
