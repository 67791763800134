.grid {
  height: 100%;
  pointer-events: none;
  position: absolute;
  z-index: 900;

  .week {
    border-right: 1px solid #dee2e6 !important;
    display: inline-block;
    height: 100%;
    position: relative;

    &:first-child {
      .day:first-child {
        border-left: 1px solid #dee2e6 !important;
      }
    }
  }

  .day {
    border-right: 1px solid #dee2e6 !important;
    border-top: 1px solid #dee2e6 !important;
    display: inline-block;
    height: 100%;
  }

  .weekend {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
