#calendar {
  position: relative;
}

.calendar-actions {
  .calendar-actions-start {
    gap: 2rem;
  }

  .calendar-actions-filters {
    gap: 0.6rem;
  }

  .calendar-actions-links {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    gap: 1rem;
    height: 36px;

    button,
    a {
      border: unset;
      border-radius: 5px;
      color: #566965;
      font-size: 14px;
      font-weight: 500;
      height: 35px;
      justify-content: center;
      width: 150px;

      &:hover {
        text-decoration: none;
      }

      &.active {
        background-color: #566965;
        color: #fff;
      }
    }
  }

  .custom-select {
    border: 1px solid #dee2e6;
    color: #566965 !important;
    font-size: 14px;
    height: 36px;

    option {
      color: #566965 !important;
    }
  }

  .calendar-archived-projects {
    flex-shrink: 0;
    gap: 0.4rem;

    .calendar-archived-check {
      border: 1px solid #dee2e6;
      border-radius: 5px;
      height: 20px;
      width: 20px;

      &.checked {
        background-color: #566965;
      }
    }

    label {
      color: #566965;

      margin-bottom: 0;
    }
  }
}
